! function (t, n) {
  function e() {
    if (!a) {
      a = !0;
      for (var t = 0; t < d.length; t++) d[t].fn.call(window, d[t].ctx);
      d = []
    }
  }

  function o() {
    "complete" === document.readyState && e()
  }
  t = t || "docReady", n = n || window;
  var d = [],
    a = !1,
    c = !1;
  n[t] = function (t, n) {
    if ("function" != typeof t) throw new TypeError("callback for docReady(fn) must be a function");
    return a ? void setTimeout(function () {
      t(n)
    }, 1) : (d.push({
      fn: t,
      ctx: n
    }), void("complete" === document.readyState ? setTimeout(e, 1) : c || (document.addEventListener ? (document.addEventListener("DOMContentLoaded", e, !1), window.addEventListener("load", e, !1)) : (document.attachEvent("onreadystatechange", o), window.attachEvent("onload", e)), c = !0)))
  }
}("docReady", window);

String.prototype.capitalize = function (lower) {
  return (lower ? this.toLowerCase() : this).replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
  // 'javaSCrIPT'.capitalize(); // -> 'JavaSCrIPT'
  // 'javaSCrIPT'.capitalize(true); // -> 'Javascript'
};

String.prototype.isEmpty = function () {
  msg = this.replace(/\s+/g, '');
  return (msg.length === 0 || !msg.trim());
};

(function () {
  var method;
  var noop = function () {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});
  while (length--) {
    method = methods[length];
    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());


(function () {
  jQuery.easing.def = "easeOutQuad";

  /**
   * @Form-erros
   * Remover as classes de erro adicionadas pela validaçáo
   */
  formErrors();

  /**
   * @Styled-select
   * Aplicar um novo estilo as selects.
   */
  // styledSelect();

  if (!!document.querySelector('.scrollto')) {
    //http://iamdustan.com/smoothscroll/
    //https://css-tricks.com/snippets/jquery/smooth-scrolling/
    // Select all links with hashes
    $('a.scrollto')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function (event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000, function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              };
            });
          }
        }
      });
  }

  if (!!document.querySelector('.slick-slider-for')) {
    $('.slick-slider-for').slick({
      dots: false,
      infinite: false,
      speed: 750,
      slidesToShow: 1,
      adaptiveHeight: true,
      autoplay: false,
      autoplaySpeed: 3500,
      arrows: false,
      fade: true,
      asNavFor: '.slick-slider-nav',
      lazyLoad: 'progressive'
    });
    $('.slick-slider-nav').slick({
      dots: true,
      infinite: false,
      speed: 750,
      slidesToShow: 1,
      adaptiveHeight: true,
      autoplay: false,
      autoplaySpeed: 3500,
      asNavFor: '.slick-slider-for',
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive'
    });
  }

  if (!!document.querySelector('.slick-slider')) {
    $('.slick-slider').slick({
      dots: true,
      infinite: false,
      speed: 750,
      slidesToShow: 1,
      adaptiveHeight: true,
      autoplay: false,
      autoplaySpeed: 3500,
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive'
    });
  }

  if (!!document.querySelector('.slick-banner')) {
    $('.slick-banner').slick({
      dots: true,
      infinite: false,
      speed: 750,
      fade: true,
      cssEase: 'linear',
      slidesToShow: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive'
    });
  }

  if (!!document.querySelector('.slick-carousel')) {
    $('.slick-carousel').slick({
      dots: true,
      infinite: false,
      speed: 750,
      slidesToShow: 3,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 4500,
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive',
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }

  if (!!document.querySelector('.slick-carousel-modulos')) {
    $('.slick-carousel-modulos').slick({
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 3500,
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive',
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }

  if (!!document.querySelector('.slick-carousel-4')) {
    $('.slick-carousel-4').slick({
      dots: true,
      infinite: false,
      speed: 750,
      slidesToShow: 4,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 4500,
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive',
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }

  if (!!document.querySelector('.slick-carousel-6')) {
    $('.slick-carousel-6').slick({
      dots: true,
      infinite: false,
      speed: 750,
      slidesToShow: 6,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive',
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 460,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }]
    });
  }

  if (!!document.querySelector('.slick-carousel-5')) {
    $('.slick-carousel-5').slick({
      dots: true,
      infinite: true,
      speed: 500,
      centerMode: true,
      centerPadding: '4rem',
      slidesToShow: 5,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive',
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }

  if (!!document.querySelector('.slick-carousel-clientes')) {
    $('.slick-carousel-clientes').slick({
      dots: false,
      infinite: true,
      speed: 2000,
      centerMode: false,
      // centerPadding: '4rem',
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      // prevArrow: '<button class="btn btn-primary slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
      // nextArrow: '<button class="btn btn-primary slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
      lazyLoad: 'progressive'
    });
  }
}());

/**
 * @Header Affix
 * Animação da header da página.
 */
function affixHeader() {
  if (!!document.querySelector('#maniHeader')) {
    setTimeout(function () {
      var gb_H = $('#maniHeader').outerHeight();
      animeHeader(gb_H);
      $(window).scroll(function () {
        var gb_H_new = $('#maniHeader').outerHeight();
        animeHeader(gb_H, gb_H_new);
      });
    }, 100);
  } else {
    return;
  }
}

function animeHeader(gb_H, gb_H_new) {
  var scroll = getCurrentScroll();

  if ($('body').is('.-affix-header')) {
    $('#maniHeader').addClass('affix-header');

    if (scroll > $('.hero').outerHeight()) {
      $('#maniHeader').addClass('fixed');
    } else {
      $('#maniHeader').removeClass('fixed');
    }
  }
}

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

function formErrors() {
  if (!!document.querySelector('input.error')) {
    $("body").on("focus", "input.error, select.error, textarea.error", function () {
      $(this).removeClass('error');
    });
  }
  if (typeof ($('input.success')) !== 'undefined' && $('input.success') !== null) {
    $("body").on("focus", "input.success, select.success, textarea.success",
      function () {
        $(this).removeClass('success');
      });
  }
}

// function styledSelect() {
//   if (typeof ($('select')) !== 'undefined' && $('select') !== null) {
//     $('select').each(function () {
//       if (!$(this).parent().hasClass("styled-select"))
//         $(this).wrap('<div class="styled-select input form-control" role="presentation"></div>');
//
//       // if ($(this).parent().hasClass("styled-select")) {} else {
//       //   classes = {};
//       //   $($(this).attr('class').split(' ')).each(function () {
//       //     if (this !== '') {
//       //       classes[this] = this;
//       //     }
//       //   });
//       //   $(this).wrap('<div class="styled-select" role="presentation"></div>');
//       //   for (var class_name in classes) {
//       //     $(this).closest('.styled-select').addClass(class_name);
//       //   }
//       // }
//     });
//   }
// }