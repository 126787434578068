// Titulo
// ==========================================================================
// (function () {
//   try {
//     if (!isInPage(".el"))
//       return false;
//   } catch (error) {
//     console.log(error.name + ": " + error.message);
//   }
// }());

// https://developer.mozilla.org/en-US/docs/Web/API/Node/contains
function isInPage(element) {
    var node = document.querySelector(element);
    return (node === document.body) ? false : document.body.contains(node);
}

function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

jQuery(document).ready(function (e) {



    // Mostrar tabs na questão
    // ==========================================================================
    (function () {
        try {
            if (!isInPage(".question-footer .nav-link"))
                return false;

            $('.question-footer .nav-link').on('click', function (e) {
                e.preventDefault();
                $(this).tab('show');
            })

            $('.question-footer .close-tab').on('click', function (e) {
                e.preventDefault();
                $('.question-footer .nav-link').removeClass('active');
                $('.question-footer .tab-pane').removeClass('active show');
            })

        } catch (error) {
            console.log(error.name, ": ", error.message);
        }
    }());
    // // Mostrar tabs na questão
    // // ==========================================================================
    // (function () {
    //   try {
    //     if (!isInPage(".question-footer .nav-link"))
    //       return false;
    //
    //     $('.question-footer .nav-link').on('click', function (e) {
    //       console.log('error.name + ": " + error.message');
    //       e.preventDefault();
    //       $(this).tab('show')
    //     })
    //
    //   } catch (error) {
    //     console.log(error.name + ": " + error.message);
    //   }
    // }());

    // Bootstrap Tooltip
    // ==========================================================================
    (function () {
        try {
            if (!isInPage(".js-btn-menu-account"))
                return false;

            $('.js-btn-menu-account').click(function (e) {
                $('.account-menu').toggleClass('-show');
            });

        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());

    // Bootstrap Tooltip
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("[data-toggle='tooltip']"))
                return false;

            $('[data-toggle="tooltip"]').tooltip()
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());

    // Awesome Select
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("select"))
                return false;

            styledSelect();
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());

    // Fixar o menu no topo da página
    // ==========================================================================
    (function () {
        try {
            if (!isInPage(".page.-fixed-header"))
                return false;

            var page = document.getElementById("page");
            var header = document.getElementById("mainHeader");
            var masthead = document.getElementById("masthead");
            var content = document.getElementById("mainContent");
            var auxHeightheader = header.clientHeight;

            window.addEventListener("scroll", fixarHeader);

            function fixarHeader() {
                var pontoDeCorte = !!masthead ? masthead.offsetHeight : header.offsetHeight;

                if (window.pageYOffset >= pontoDeCorte) {
                    header.classList.add("is-fixed");
                    document.body.style.paddingTop = auxHeightheader + "px";
                } else {
                    header.classList.remove("is-fixed");
                    document.body.style.paddingTop = 0;
                }
            }

        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());


    // Gerar o menu para a versão mobile
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("#mobileMenu"))
                return false;

            $("#mobileMenu").mmenu({
                extensions: ["pagedim-black", "position-left", "listview-50", "border-offset"],
                navbar: {
                    title: "Livraria APCON"
                },
                navbars: [{
                    position: "bottom",
                    content: [
                        "<a class='fa fa-envelope' href='#/'></a>",
                        "<a class='fa fa-twitter'  href='#/'></a>",
                        "<a class='fa fa-facebook' href='#/'></a>"
                    ]
                }]
            });
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());


    // Multimídia - Galeria de fotos
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("#multimidiaGallery"))
                return false;

            $("#multimidiaGallery .mgallery").lightGallery({
                thumbnail: true,
                animateThumb: false,
                showThumbByDefault: true,
                download: false,
                share: false,
                actualSize: false
            });
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());

    // Animação ao fazer scroll na página
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("[data-aos]"))
                return false;

            AOS.init({
                duration: 500
            });

        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());


    // Datepicker
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("#datepicker"))
                return false;

            $('#datepicker').datepicker({
                language: 'pt-BR',
                minDate: new Date()
            });

        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());


    // Formulario de Newsletter
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("#formNewsletter"))
                return false;

            $('#formNewsletter').validate({
                debug: false,
                errorClass: "error",
                errorElement: "div",
                onkeyup: false,
                rules: {
                    emailNewsletter: {
                        required: true,
                        email: true
                    }
                }
            });
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());


    // Revolution Slider - home
    // ==========================================================================
    (function () {
        try {
            if (!isInPage("#homeSlider"))
                return false;

            var homeSlider = $('#homeSlider').show().revolution({
                sliderType: "standard",
                sliderLayout: "fullwidth",
                minHeight: '400',
                delay: 9000,
                // extensions: "",
                navigation: {
                    arrows: {
                        style: "hermes",
                        enable: true,
                        hide_onmobile: true,
                        hide_under: 600,
                        hide_onleave: true,
                        hide_delay: 200,
                        hide_delay_mobile: 1200,
                        tmp: '<div class="tp-arr-allwrapper"><div class="tp-arr-imgholder"></div></div>',
                        left: {
                            h_align: "left",
                            v_align: "center",
                            h_offset: 0,
                            v_offset: 0
                        },
                        right: {
                            h_align: "right",
                            v_align: "center",
                            h_offset: 0,
                            v_offset: 0
                        }
                    }
                },
                gridwidth: [1200, 992, 460, 360, 320],
                gridheight: [400, 400, 460, 320]
            });
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());


    // Slider de Produtos
    // ==========================================================================
    (function () {
        try {
            if (!isInPage(".carousel"))
                return false;

            $('.carousel').slick({
                dots: false,
                infinite: false,
                speed: 750,
                slidesToShow: 4,
                adaptiveHeight: true,
                autoplay: true,
                autoplaySpeed: 4500,
                arrows: true,
                prevArrow: '<button class="btn btn-link slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
                nextArrow: '<button class="btn btn-link slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
                lazyLoad: 'progressive',
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '3rem',
                        slidesToScroll: 1
                    }
                }]
            });
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());


    // Slider de Depoimentos
    // ==========================================================================
    (function () {
        try {
            if (!isInPage(".listagem-depoimento"))
                return false;

            $('.listagem-depoimento').slick({
                dots: false,
                infinite: true,
                speed: 750,
                slidesToShow: 2,
                centerMode: true,
                centerPadding: '13%',
                adaptiveHeight: true,
                autoplay: true,
                autoplaySpeed: 4500,
                arrows: true,
                prevArrow: '<button class="btn btn-link slide-control -prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
                nextArrow: '<button class="btn btn-link slide-control -next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
                lazyLoad: 'progressive',
                responsive: [{
                    breakpoint: 1400,
                    settings: {
                        centerPadding: '6rem'
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '5rem'
                    }
                }, {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        centerPadding: '4rem'
                    }
                }, {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '3rem'
                    }
                }]
            });
        } catch (error) {
            console.log(error.name + ": " + error.message);
        }
    }());
});