function alertWarning(mensagem, callback) {
    Swal.fire({
        position: 'center',
        type: 'warning',
        title: mensagem
    }).then(callback);
}

function alertWarningWithCancel(mensagem, callback) {
    Swal.fire({
        position: 'center',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: '<i class="fa fa-check-circle-o"></i>',
        cancelButtonText: '<i class="fa fa-close"></i>',
        title: mensagem
    }).then(callback);
}

function alertError(mensagem) {
    Swal.fire({
        position: 'center',
        type: 'error',
        title: mensagem
    });
}

function alertErrorWithCallback(mensagem, callback) {
    Swal.fire({
        position: 'center',
        type: 'error',
        title: mensagem
    }).then(callback);
}

function alertSuccess(mensagem, callback) {
    Swal.fire({
        position: 'center',
        type: 'success',
        title: mensagem
    }).then(callback);
}



function showSpinner() {
    $(".loading-io").show();
}

function showSpinner(message) {
    $(".loading-io").show();
    $("#spinner-description").text(message);
}

function hideSpinner() {
    $(".loading-io").hide();
}
